import React from 'react'
import "../style/Contact.css"

const Contact = () => {
  return (
  <div className='contact-container'>
    <div className="centered-text-contact  p-6 rounded-lg shadow-md max-w mx-auto text-center">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact us</h2>
      <div className="flex flex-col items-center">
        <div className="flex items-center mb-4">
          <span className="text-2xl text-blue-500 mr-3">📞</span>
          <span className="text-xl text-gray-700">00436602923444</span>
        </div>
        <div className="flex items-center">
          <span className="text-2xl text-blue-500 mr-3">📍</span>
          <span className="text-xl text-gray-700">Innsbruker Straße 19, 6300, Wörgl</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Contact