import React, { useRef } from 'react';
import Slider from 'react-slick';
import "../style/ReviewSlider.css";

// Star component to display the stars
const Stars = ({ count }) => {
  const totalStars = 5;
  return (
    <div>
      {[...Array(totalStars)].map((star, index) => (
        <span key={index}>
          {index < count ? '⭐' : '☆'}
        </span>
      ))}
    </div>
  );
};

// Main slider component
const ReviewSlider = ({ reviews }) => {
  // Create a reference for the slider
  const sliderRef = useRef(null);

  // Slick slider settings
  const settings = {
    dots: false, // Remove the dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
  };

  // Function to handle Next button click
  const next = () => {
    sliderRef.current.slickNext();
  };

  // Function to handle Previous button click
  const prev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div style={{ position: 'relative', width: '60%', margin: '0 auto' }}>
      <Slider ref={sliderRef} {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-card" style={{ padding: '20px', textAlign: 'center', border: '1px solid #ddd', borderRadius: '10px', backgroundColor: '#f9f9f9' }}>
            <h2>{review.header}</h2>
            <p>{review.description}</p>
            <Stars count={review.rating} />
          </div>
        ))}
      </Slider>

      {/* Custom navigation arrows */}
      <button onClick={prev} className="arrow-button prev-arrow">
        &#9664; {/* Left arrow symbol */}
      </button>
      <button onClick={next} className="arrow-button next-arrow">
        &#9654; {/* Right arrow symbol */}
      </button>
    </div>
  );
};

export default ReviewSlider;
