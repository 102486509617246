import React, { useEffect, useRef } from 'react';
import '../style/Home.css'; // Ensure this path is correct
import Footer from './Footer';
import Employee from './Employee';
import ScrollToTop from './ScrollToTop ';
import ReviewSlider from './ReviewSlider';

const Home = () => {
  const serviceRef = useRef(null); // Create a reference to the services section

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          }
        });
      },
      { threshold: 0.1 }
    );

    const serviceItems = serviceRef.current.querySelectorAll('.service-item');
    serviceItems.forEach((item) => observer.observe(item));

    return () => {
      serviceItems.forEach((item) => observer.unobserve(item)); 
    };
  }, []);

  const reviews = [
    {
      header: 'Amazing company',
      description: 'It was a pleasure dealing with you and I advise everyone to do so',
      rating: 5,
    },
    {
      header: 'Great job',
      description: 'You gave me exactly what I wanted, thank you',
      rating: 4,
    },
    {
      header: 'Great Product!',
      description: 'This product exceeded my expectations and I absolutely love it!',
      rating: 5,
    },
  ];

  return (
    <>
      <div className="home-container">
        <div className="centered-text-home  p-6 rounded-lg shadow-md max-w mx-auto text-center">
          <h1>
            Welcome to smart midia
          </h1>
        </div>
      </div>
      <section ref={serviceRef} className="services-section py-12">
        <h2 className="text-center text-3xl font-bold mb-8">Our Services</h2>

        <div className="p-5 container mx-auto flex justify-around ">
          <div className="service-item border border-gray-300 rounded-lg shadow-lg p-4 flex flex-col items-center">
            <img 
              src="/assets/Socialmediamanagement.png"  
              alt="Service 1" 
              className="w-128 h-64 object-cover"
            />
            <p className="text-center mt-10 text-lg font-semibold">Social media management</p>
          </div>

          <div className="service-item border border-gray-300 rounded-lg shadow-lg p-4 flex flex-col items-center">
            <img 
              src="/assets/Onlinecardservice.png" 
              alt="Service 2" 
              className="w-128 h-64 object-cover"
            />
            <p className="text-center mt-10 text-lg font-semibold">Online card service</p>
          </div>

          <div className="service-item border border-gray-300 rounded-lg shadow-lg p-4 flex flex-col items-center">
            <img 
              src="/assets/Electronicrecruitmentservice.jpg"  
              alt="Service 3" 
              className="w-128 h-64 object-cover"
            />
            <p className="text-center mt-10 text-lg font-semibold">Electronic recruitment service</p>
          </div>
        </div>
      </section>
      <Employee />
      <div className="App p-10">
        <h1 className="text-center text-3xl font-bold">Customer Reviews</h1>
        <ReviewSlider reviews={reviews} />
      </div>
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Home;
