import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <p className="text-sm">&copy; {new Date().getFullYear()} MyApp. All Rights Reserved.</p>
        <p className="text-sm mt-2">
          Address: Innsbruker Straße 19, 6300, Wörgl | Phone: 00436602923444
        </p>
        
      </div>
    </footer>
  );
};

export default Footer;
