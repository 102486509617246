import React from 'react';
// import "../style/About.css"
import ScrollToTop from './ScrollToTop ';

const About = () => {
  return (<>
    <div  className="about-container flex justify-center items-center min-h-screen bg-gray-100 px-4">
      <div className="centered-text-about w-full sm:w-3/4 md:w-1/2 max-w-full bg-white shadow-lg rounded-lg p-6 sm:p-8">
        <h1 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">Privacy Policy</h1>
        <h3 className="font-bold">
          Last Updated AUGUST 2024
        </h3>
        <h3 className="font-bold">
          1.Overview
        </h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">
        Smartmidia Inc. (“Smartmidia,” “we,” “us,” and “our”) respects your privacy and is committed to protecting it through compliance with this Privacy Policy (“Privacy Policy”). This Privacy Policy describes how we collect and use your Personal Information when you visit our website at <a href='https://www.goSmartmidia.com'>https://www.goSmartmidia.com</a> or otherwise use the Platform as described further in the Terms of Service, and that you may provide in electronic messages to Smartmidia.
Please read this Privacy Policy to understand our policies and practices regarding your Personal Information and how we will handle it. If you do not agree with our policies and practices, do not use the Platform.  By accessing or using the Platform, you agree and consent to this Privacy Policy.
Smartmidia may change this Privacy Policy at any time, at its discretion. Your continued use of the Platform after we make changes is deemed to be acceptance of and consent to those changes, so please check the Privacy Policy periodically for updates.
This Privacy Policy is subject to and governed by the Smartmidia Terms of Service. The Services are part of the Platform and are described further in the Terms of Service.
</p>
        <h3 className="font-bold">
        2. The Types of Information That Smartmidia Collects About You and How Smartmidia Collects Information About You
        </h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">
        Smartmidia may collect two types of information from you when you visit the Platform: Personal Information and Non-Personal Information (collectively “Information”).<br></br>
•	“Personal Information” refers to data by which you may be personally identified, such as name, email address, employer, job title and department, and telephone number.<br></br>
•	“Non-Personal Information” means data that is about you, but does not identify you specifically.If you do nothing during your visit to our Platform but browse, read pages, or view content, we will gather and store Information about your visit that does not identify you personally.<br></br>
We collect Information:<br></br>
•	Directly from you when you provide it to us. When you subscribe to a newsletter, create an account, make a purchase or request information from Smartmidia, we will ask for things like your name, contact, billing, shipping and communication information, and account ID or credentials in order to fulfill your request. If you set up an appointment with us through the Platform, attend a trade show or event, or otherwise contact us, you may also voluntarily provide similar information.  If you submit any Personal Information about other people to us or to our service providers, you are responsible for making sure that you have the authority to do so and to allow us to use their Personal Information in accordance with this Privacy Policy (for example, by You asking for their consent).<br></br>
•	From third parties. We obtain information through partners, vendors, suppliers and other third parties.  The parties from whom we obtain information are typically corporate enterprises (although some may also be educational or public enterprises) and they may be located in any of the locations in which we do business.  These enterprises largely fall into the following categories: Advertising and marketing companies, data set and information vendors, public database providers, social media platforms, partners, providers of products or services, hosts or vendors at events or trade shows, research partners, or enterprises that use Smartmidia Services.  We take steps to confirm that information we receive from these third parties has been collected with your consent or that these parties are otherwise legally permitted to disclose your Personal Information to us.  We might also obtain information through a partner, or co-create datasets with a partner, as part of our business operations.  This kind of data is used for work like improving the Platform and other Smartmidia Services, enhancing existing products and developing new capabilities and features.  In some cases we combine Personal Information about individuals that we receive from multiple sources, including directly collected from you or through your use of the Platform.<br></br>
•	Automatically as you navigate through the Platform or during the time in which you utilize our Services.  We collect information about how you interact with the Platform through the use of cookies, pixel tags, and similar technologies.  Please view our separate Cookies Policy for more information on our use of cookies and similar technologies, how you can manage cookies and how we respond to Do Not Track signals.
        </p>
        <h3 className="font-bold">3. How Smartmidia Uses Personal Information It Collects About You and the Purposes for the Collection and Use</h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">We use Personal Information that we collect about you or that you provide to us for the following purposes:<br></br>
•	For Functionality and Development of the Platform and Smartmidia Services. We use information to provide, offer, and personalize the Platform and other Smartmidia Services provided to you.  Some information, like your IP address, is used to communicate with your device to provide network connectivity, measure usage levels of the Platform, diagnose server problems and provide security features.  Other business purposes that depend on use of your information include data analysis related to testing, modifying, improving or developing new products, services and technologies, and to identify trends. Some Platform features may leverage artificial intelligence (“AI”) tools to enhance performance capabilities. We do NOT retain Personal Information to develop, improve, or train generalized AI or machine learning models, including user data provided via third party APIs, including but not limited to Google Workspace APIs. Smartmidia Platform’s use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements. We use cookies to make our websites and the Platform operate, work more efficiently, and provide analytic information. Technologies similar to cookies, such as pixel tags are also used in connection with the Platform. For more information on our use of cookies, please read our Cookies Policy.<br></br>
•	For Customer Support, Platform Updating and Reporting. The Platform may use information to provide Smartmidia with updates and reports, and to check that the Platform is working properly.  Update functions may automatically check your system to see whether files need to be refreshed, updated, or modernized, in order to provide you with the up-to-date security, versions, features, options and controls associated with your systems or devices.  We rely on information to analyze performance and improve and maintain the Platform.  We also rely on Personal Information you provide to us to provide you with customer support for the Platform and other Smartmidia Services, and to verify eligibility for promotional offers.<br></br>
•	For Business Operations. We use information to operate our business; for example, to perform accounting, auditing, billing, reconciliation, and collection activities. Other business purposes that depend on use of your Personal Information include crime or fraud monitoring and prevention, protecting our legal rights, and performing contractual obligations.  We also use Personal Information to contact you to advertise, market and sell Smartmidia Services in accordance with your communications preferences.<br></br>
•	To Communicate. We use contact information to send messages; to provide Smartmidia Services; to respond to customer service requests; to provide alerts such as security updates or changes in our policies or about subscriptions that are ending; and to send marketing or informational materials like newsletters or white papers, in accordance with your communication preferences.  We occasionally conduct surveys, or do focused research or studies which may require you to voluntarily share Personal Information in order to participate.  These activities typically have additional notices that provide more information about the use of your Personal Information and to which you may be asked to consent.<br></br>
•	For Advertising and Marketing. We may use Personal Information collected from you, combined with information about what advertisements you viewed and other information we collect, to enable us to provide personalized content and to study the effectiveness of advertising and marketing campaigns.  You may choose whether to allow or deny uses or sharing of your device’s location by changing your device settings, but if you choose to deny such uses or sharing, we may not be able to provide you with certain personalized Smartmidia Services and content on the Platform.<br></br>
•	For Statistical Purposes to Improve the Platform. We may compile Platform statistics into traffic reports, which help Smartmidia understand, anticipate, and respond to user needs.  If we learn, for example, of heightened interest in certain aspects of the Smartmidia Platform, we are likely to highlight that information on the Platform home page. This Information helps us create a better overall experience for Platform users.<br></br>
LEGAL BASIS FOR OUR USE (APPLICABLE ONLY TO EEA AND UNITED KINGDOM VISITORS):  If you are in the European Economic Area or the United Kingdom, our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it.
However, we will collect Personal Information from you only where we have your consent to do so, where we need the Personal Information to perform a contract with you, where we have a legal obligation to do so, or where the processing is in our legitimate interests (such as processing for administrative purposes, direct marketing, product development or improvement, preventing fraud or criminal acts and in support of information security) and not overridden by your data protection interests or fundamental rights and freedoms. <br></br>
If we ask you to provide Personal Information to comply with a legal requirement or to perform a contract with you, we will make this clear at the time of collection. We will also tell you whether the requirement for that information is mandatory and explain any consequences to you if you do not provide the information.<br></br>
Similarly, if we collect and use your Personal Information based on our legitimate interests (or those of any third party), we will take reasonable steps to provide clear notice and describe our legitimate interests.<br></br>
Smartmidia is the Data Controller of all Personal Information collected through the Platform in the EEA and the United Kingdom, except with respect to the Services (where Smartmidia is the Data Processor) or where a supplemental Privacy Policy says otherwise. The contact details for Smartmidia are set out in the "HOW TO CONTACT US” section.<br></br>
If you have questions about or need further information concerning the legal basis on which we collect and use your Personal Information for any specific processing activity, please contact us using the contact details provided under the “HOW TO CONTACT US” Section below.<br></br>
RETENTION OF YOUR PERSONAL INFORMATION: We will only retain your Personal Information for as long as reasonably necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements and for other purposes described in this Privacy Policy.  We may retain your Personal Information for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.<br></br>
To determine the appropriate retention period for Personal Information, we consider the amount, nature and sensitivity of the Personal Information, the potential risk of harm from unauthorized use or disclosure of your Personal Information, the purposes for which we process your Personal Information and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.  In some circumstances you can ask us to delete your data: see “YOUR LEGAL RIGHTS” below for further information.  And in some circumstances we will anonymize your Personal Information (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.<br></br>
</p>
<h3 className="font-bold">
4. How Smartmidia Protects Your Information
</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
The Platform is designed to provide reasonable and appropriate administrative, technical and organizational security measures to protect your Personal Information against risks such as temporary or permanent loss, destruction, and unauthorized or unlawful access, alteration, use or disclosure.  We require our suppliers and vendors to apply similar protections when they access or use Personal Information that we share with them.  Users of the Platform must also do their part in protecting the data, systems, networks, and service they are utilizing.  No technology, data transmission or system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that your password to any Smartmidia account has been compromised), please immediately notify us by contacting us using the instructions in the “HOW TO CONTACT US” section below.
</p>
<h3 className="font-bold">5. When Smartmidia Shares Your Information</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
We work through our affiliates to provide the Platform and other Smartmidia Services.  We also work with authorized suppliers and business partners.  When we share your Personal Information with these companies, we put in place appropriate measures to limit the use of your information only for legal and authorized purposes that are consistent with this Privacy Policy, as well as appropriate confidentiality and security measures.<br></br>
We also share information with third parties for advertising and marketing; when required by law or to respond to legal process; to protect our customers; to protect lives; to maintain the security of the Platform; and to protect our legal rights.  We may disclose Personal Information that we collect or you provide as described in this Privacy Policy:<br></br>
•	With Affiliates and Subsidiaries. For purposes limited to and consistent with this Privacy Policy.<br></br>
•	With Suppliers. Our authorized vendors and suppliers may require Personal Information to provide services we have contracted for, such as product delivery, website hosting, data analysis, IT services, auditing, or customer service.  We use a wide variety of software and tools at Smartmidia, and we process Personal Information using these tools as a regular course of business.  Our contracts with suppliers and vendors include provisions to protect your Personal Information and limit its use.<br></br>
•	With Partners. We occasionally have relationships with third parties that are not suppliers or vendors but are working with us to offer certain opportunities such as marketing and similar promotions, to enable joint products or research studies, or to facilitate services on the Platform.  In these cases, additional terms or Privacy Policies may be provided.  For third parties or uses not described in this Privacy Policy, we share your information only with a lawful basis to do so.<br></br>
•	For Advertising and Marketing. We share your information with our third-party company partners to prepare and deliver advertising and marketing content, to provide content services and to enable them to provide you with more personalized ads and to study the effectiveness of our campaigns.<br></br>

In particular, we use third-party companies to communicate regarding goods and services that may be of interest to you, in accordance with your preferences.  You may receive this content by a variety of means such as email, phone or when you access and use the Platform or other Smartmidia Services, and other websites.  Content may be based on information obtained, for example, through prior purchases or transactions, through your device’s physical location, through information about what advertisements and content you have viewed, or through cookies and similar technologies relating to your access to and use of the Platform and other websites. Please read our Cookies Policy for more information. You can choose whether to allow or deny uses and/or sharing of your device’s location by changing your device settings, but if you choose to deny such uses or sharing, our partners may not be able to provide you with the applicable Platform Services and content.<br></br>
•	For Connections to Social Networks and Third-Party Services. Where you are using our Services and have chosen to connect your social networks profiles to the Smartmidia Services, or if you authorize a third party service to access your account, you are agreeing to provide information about you to the social networks and the third party services under their respective terms and privacy policies. For example, if you choose to connect your YouTube account to the Smartmidia Services, this connection uses YouTube’s API services, and the Google Privacy Policy (located here) will apply to you. If you have authorized us to access your information via the YouTube API services, in addition to our normal procedure for deleting stored data, you may revoke our access to your data via the Google security settings page, located here.<br></br>
•	Sales, Mergers & Acquisitions. We may disclose Personal Information as part of a contemplated or actual corporate transaction such as a reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).<br></br>
•	With Your Consent. Smartmidia may disclose your Personal Information to any other person or entity where you consent to the disclosure. For information about how to decline our advertising and marketing, please see the section entitled "Your Choices & Rights" below.<br></br>
•	We do NOT share your phone number or opt-in consent with third parties unless we’ve received your express written consent to do so.<br></br>
We also share non-personally identifiable information, such as anonymized or aggregated information, with suppliers for purposes such as analysis, identifying trends in the areas of our products and to help research and develop new Smartmidia Services.<br></br>
Smartmidia does not sell any of your Personal Information for monetary compensation.<br></br>
</p>
<h3 className="font-bold">6. Information from Children</h3> 
<p className="text-gray-700 text-xs sm:text-sm mb-3">
We do not knowingly collect, use, or disclose Information from children under 16.  If we learn that we have collected the Personal Information of a child under 16—or the equivalent minimum age depending on the jurisdiction, such as 13 in the United States per the Children’s Online Privacy Protection Act—we will take steps to delete the information as soon as possible.  If you are under 16, do not provide any Information about yourself to Smartmidia, including your name, address, telephone number or email address. If you become aware that Information of a child under 16 years of age has been provided, please use one of the methods provided under the “HOW TO CONTACT US” section below.
</p>
<h3 className="font-bold">7. Links to Other Websites and Services</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">We are not responsible for the practices employed by websites or services linked to or from the Platform, including the information or content contained therein.  This Privacy Policy does not address, and we are not responsible for, the policies and practices of third parties or other organizations that are not operating on Smartmidia’s behalf, including policies and practices related to privacy and security, data collection, processing, use, storage, and disclosure.  This includes: (a) any third party operating any site or service to which the Platform links – the inclusion of a link on the Platform does not imply endorsement of the linked site or service by us or by our affiliates; or (b) any app developer, app provider, social media platform provider, operating system provider, wireless service provider or device manufacturer (such as Facebook, Apple, Google, Microsoft, LinkedIn, etc.) - including any Personal Information you disclose to other organizations through or in connection with the Platform or other Smartmidia Services.</p>
<h3 className="font-bold">8. Do Not Track</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">className="text-gray-700 text-sm mb-3"</p>
<h3 className="font-bold">9. YOUR LEGAL RIGHTS</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">Smartmidia respects your rights in how your Personal Information is used and shared. Depending on where you live, you may have rights to request access or corrections to your personal data and make choices about the kinds of marketing materials you receive (or choose not to receive marketing from Smartmidia at all). See below for more information, depending on your location.</p>
<h3 className="font-bold">10. European Privacy Rights</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
If you are in Europe, you may have additional rights under the GDPR, the UK GDPR, or nFADP.  Additional choices and rights may be available to you depending on which Smartmidia Services you use.<br></br>
•	Access, Correction to or Deletion of Your Information. If you would like to correct or update your Personal Information, or to request access to or deletion of your Personal Information, you may contact us by visiting the Platform or by using the contact details provided under the “HOW TO CONTACT US” section below.  If you request a change to or deletion of your Personal Information, please note that we may still need to retain certain information for recordkeeping purposes, and/or to complete any transactions that you began prior to requesting such change or deletion (e.g., when you make a purchase or enter a promotion, you may not be able to change or delete the Personal Information provided until after the completion of such purchase or promotion). Some of your information may also remain within our systems and other records where necessary for compliance with applicable law.<br></br>

At your request and where the law requires us to do so, we will confirm what Personal Information we hold about you.  You may also have a legal right to obtain a copy of your Personal Information.  You can make such a request by making a written request in one of the ways described in the “HOW TO CONTACT US” section below.  We may charge a processing fee for this service where permitted by law and we will require evidence of your identity before fulfilling your request.<br></br>
•	Data Privacy Rights Specific to Individuals in the European Economic Area, the United Kingdom, and Switzerland. You can object to processing of your Personal Information, ask us to restrict processing of your Personal Information, or request portability of your Personal Information.  You can exercise these rights by making a written request in one of the ways described in the “HOW TO CONTACT US” section below.<br></br>

Similarly, if we have collected your Personal Information with your consent, you can withdraw your consent at any time.  Withdrawing your consent will not affect (1) the lawfulness of any processing we conducted prior to your withdrawal, or (2) processing your Personal Information under other legal bases.<br></br>

If you believe we are using your Personal Information in a way that is inconsistent with this Privacy Policy or for more information about your rights, contact your local data protection authority.  Additionally, under the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and/or the Swiss-U.S. DPF, you may contact JAMS Mediation, Arbitration, and ADR Services (<a href='https://www.jamsadr.com/eu-us-data-privacy-framework'>https://www.jamsadr.com/eu-us-data-privacy-framework</a>) to address complaints and provide appropriate recourse free of charge to you.  Under certain conditions, you may invoke binding arbitration. For additional information on pre-arbitration requirements, please see Annex I of the DPF Principles.<br></br>
•	Advertising and Marketing Choices. We give you many choices regarding our use and disclosure of your Personal Information for advertising and marketing purposes.  You may access or update your contact details and modify your communication preferences by using one of the methods provided under the “HOW TO CONTACT US” section below.  Please also note that if you choose not to receive marketing communications from us, we may still send you communications related to your products or the Platform, such as information about a security update, service issue or product delivery.  Some advertising content is delivered through the Platform’s use of cookies and similar technologies.  Our Cookies Policy includes more information on Smartmidia’s use of such technologies for advertising and other purposes.<br></br>
</p>
<h3 className="font-bold">
11. International Compliance
</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
Smartmidia is a global company with its headquarters in the United States.  As such, we may transfer your Personal Information between the United States and our affiliates and business partners in other countries.  We may also transfer your Personal Information to our third party service providers, who may be located in a different country to you.<br></br>
Smartmidia transfers information internationally in order to operate efficiently, to improve performance, and to create redundancies to protect information in the event of an outage or other problem.  In so doing, we will process your Personal Information in a way that meets the commitments of this Privacy Policy and complies with the law wherever we transfer it.<br></br>
Whenever Smartmidia transfers Personal Information beyond the country of origin, we will do so in accordance with applicable laws.  For Personal Information originating in the European Economic Area (EEA), the United Kingdom (UK), or Switzerland that is transferred to a Smartmidia entity outside the EEA, UK, or Switzerland, Smartmidia participates in the EU-U.S. Data Privacy Framework (EU-U.S. DPF) and, as applicable, the UK extension to the EU-U.S. DPF, and/or the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF). Smartmidia commits to the DPF Principles and, as needed, will take additional steps to provide appropriate safeguards for the Personal Information we transfer. The Federal Trade Commission has jurisdiction over Smartmidia’s compliance with the EU-U.S. DPF, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. DPF).  Smartmidia may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. Smartmidia is liable in cases of onward transfers to third parties.<br></br>
</p>
<h3 className="font-bold">12. Data Privacy Framework</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
Smartmidia Inc. and Lead Connector LLC comply with the EU-U.S. Data Privacy Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as set forth by the U.S. Department of Commerce. Smartmidia has certified to the U.S. Department of Commerce that Smartmidia and Lead Connector adhere to the EU-U.S. Data Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing of personal data received from the European Union in reliance on the EU-U.S. DPF and from the United Kingdom (and Gibraltar) in reliance on the UK Extension to the EU-U.S. DPF. Smartmidia has certified to the U.S. Department of Commerce that Smartmidia and Lead connector adhere to the Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF Principles) with regard to the processing of personal data received from Switzerland in reliance on the Swiss-U.S. DPF. If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern. To learn more about the Data Privacy Framework (DPF) program, and to view our certification, please visit https://www.dataprivacyframework.gov/. Smartmidia further agrees to notify you without undue delay, if its self-certification under any of the Data Privacy Framework is withdrawn, terminated, revoked or otherwise invalidated and provide take appropriate steps to stop and remediate and unauthorized processing of such personal data.
</p>
<h3 className="font-bold">13. California Privacy Rights</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">
This section is addressed to California residents only and provides more information about your rights under the California Consumer Privacy Act or “CCPA” (California Civil Code Section 1798.100 et seq.), as amended. Subject to certain exceptions, the CCPA grants to California residents the rights to: be notified about the collection, use, disclosure, sale or sharing of their Personal Information; request access to, deletion of, or correction of their Personal Information; request to opt out of the “sale” or “sharing” of Personal Information (where such information is sold or shared); limit the use or disclosure of Sensitive Personal Information (as defined under CCPA); and to not be discriminated against for exercising such rights.<br></br>
Smartmidia does not sell your Personal Information.  If you wish to exercise your right to opt-out of Smartmidia using your Personal Information for cross-contextual targeted advertising purposes (called “sharing” under the CCPA), you may do so by using the details in the “HOW TO CONTACT US” section or the appropriate withdrawal mechanism provided to you on the Platform.<br></br>
You can request access to, correction of or deletion of your Personal Information by using the details in the “HOW TO CONTACT US” section.  If you request a deletion of your Personal Information, please note that Smartmidia may still need to retain certain information for recordkeeping purposes, to complete any transactions that you began prior to requesting such deletion, to comply with applicable law, or for other purposes permitted by CCPA.  If you submit a request to exercise rights under CCPA, Smartmidia will ask you to provide certain information to verify your identity.  This information will depend on your prior interactions with Smartmidia and the sensitivity of Personal Information at issue.  If Smartmidia denies your request, we will explain why.<br></br>
You can designate an authorized agent to make a request under the CCPA on your behalf in certain circumstances. If you use an authorized agent for this purpose, Smartmidia may ask you to verify your identity or that you provided the authorized agent signed permission to submit a request under the CCPA.  If you provide an authorized agent with power of attorney pursuant to Probate Code sections 4000 to 4465, it may not be necessary to perform these steps and Smartmidia will respond to any request from such authorized agent in accordance with the CCPA.<br></br>
The Privacy Policy describes the categories of Personal Information that Smartmidia collects and how Smartmidia uses such Personal Information. If Smartmidia collects Sensitive Personal Information, we limit our use of the Sensitive Personal Information to uses: (1) you have authorized, (2) that are required to fulfill your requests for goods or services, or (3) that are otherwise allowed by the CCPA or required by other laws or regulations.<br></br>
The categories of Personal Information collected, disclosed, and sold from California residents over the preceding 12 months and Smartmidia’s applicable retention periods include:<br></br>

</p>
<div className="overflow-x-auto">
<table className="min-w-full bg-white border border-gray-300 mt-4">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Personal Information Category</th>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Retention Period</th>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Business Purpose</th>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Collected</th>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Disclosed</th>
              <th className="py-2 px-4 border-b border-gray-300 text-left text-sm">Sold</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Identifiers (such name, address, IP address, email, etc.)</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">See Section 3 of Privacy Policy: “Retention of Your Personal Information”</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">For functionality, customer support, business operations, communication, advertising and marketing, and statistical purposes.</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Yes</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Personal information defined in Civil Code Section 1798.80 (such as signature, SSN, financial information, and insurance information, etc.)</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Protected personal information (such as gender, religion, sexual orientation, or disability)</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Commercial information (such as products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, etc.)</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">See Section 3 of Privacy Policy: “Retention of Your Personal Information”</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">For development, customer support, business operations, communication, advertising and marketing, and statistical purposes.</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Yes</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Biometric information</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Geolocation data</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Audio, electronic, visual, thermal, or other activity information</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Professional or employment-related information</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Education information</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Inferences (such as analytics and preferences)</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">Yes</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
              <td className="py-2 px-4 border-b border-gray-300 text-sm">No</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h3 className="font-bold">14. Colorado Privacy Rights</h3> 
        <p className="text-gray-700 text-xs sm:text-sm mb-3">
        This section is addressed to Colorado residents only and provides more information about your rights under the Colorado Privacy Act or “CPA.” Subject to certain exceptions, the CPA grants to Colorado residents the rights to: be notified about the collection, use, disclosure, or sale of their Personal Information; request access to, deletion of, or correction of their Personal Information; and request to opt out of the use of Personal Information for targeted advertising, sale, or certain profiling.<br></br>
You can request access to, correction of or deletion of your Personal Information by using the details in the “HOW TO CONTACT US” section. If you request a deletion of your Personal Information, please note that Smartmidia may still need to retain certain information for recordkeeping purposes, to complete any transactions that you began prior to requesting such deletion, to comply with applicable law, or for other purposes permitted by CPA.  If you submit a request to exercise rights under CPA, Smartmidia will ask you to provide certain information to verify your identity. This information will depend on your prior interactions with Smartmidia and the sensitivity of Personal Information at issue. If Smartmidia denies your request, we will explain why. If we have not responded to your request or asked for additional time to respond to your request within 45 days after you send us a request, you have the right to appeal our failure to take action. To appeal our failure to take action, contact us using the details in the “HOW TO CONTACT US” section.<br></br>
You can designate an authorized agent to make a request under the CPA on your behalf in certain circumstances. If you use an authorized agent for this purpose, Smartmidia may ask you to verify your identity or that you provided the authorized agent signed permission to submit a request under the CPA.<br></br>
        </p>
        <h3 className="font-bold">15. Connecticut Privacy Rights</h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">
        This section is addressed to Connecticut residents only and provides more information about your rights under the Connecticut Data Privacy Act or “CTDPA.” You can exercise your rights by using the details in the “HOW TO CONTACT US” section. If we inform you that we decline to take action regarding your request, you have the right to appeal our failure to take action by contacting us using the details in the “HOW TO CONTACT US” section.
        </p>
        <h3 className="font-bold">16. Utah Privacy Rights</h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">
        This section is addressed to Utah residents only and provides more information about your rights under the Utah Consumer Privacy Act or “UCPA.” You can exercise your rights by using the details in the “HOW TO CONTACT US” section.
        </p>
        <h3 className="font-bold">17. Virginia Privacy Rights</h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3"> 
        This section is addressed to Virginia residents only and provides more information about your rights under Virginia’s Consumer Data Protection Act or “VCDPA.” Subject to certain exceptions, the VCDPA grants to Virginia residents the rights to: be notified about the collection, use, disclosure, or sale of their Personal Information; request access to, deletion of, or correction of their Personal Information; request to opt out of the use of Personal Information for targeted advertising, sale, or certain profiling; and to not be discriminated against for exercising such rights.<br></br>
You can request access to, correction of or deletion of your Personal Information by using the details in the “HOW TO CONTACT US” section. If you request a deletion of your Personal Information, please note that Smartmidia may still need to retain certain information for recordkeeping purposes, to complete any transactions that you began prior to requesting such deletion, to comply with applicable law, or for other purposes permitted by VCDPA.  If you submit a request to exercise rights under VCDPA, Smartmidia will ask you to provide certain information to verify your identity. This information will depend on your prior interactions with Smartmidia and the sensitivity of Personal Information at issue. If Smartmidia denies your request, we will explain why. If we inform you that we decline to take action regarding your request, you have the right to appeal our failure to take action. To appeal our failure to take action, contact us using the details in the “HOW TO CONTACT US'' section.
        </p>
        <h3 className="font-bold">Privacy Statement</h3>
        <p className="text-gray-700 text-xs sm:text-sm mb-3">The site administration is obliged, within the limits allowed in accordance with the regulating law, not to disclose any personal information about the user such as address, phone number, e-mail address, and others. Furthermore, none of such information will be exchanged, traded or sold to any third party as long as it is within the possible capabilities of the site administration, and access to the information will only be allowed to qualified and professional persons who supervise the Smartmidia website.<br></br>
No Legal Liability<br></br>

The user acknowledges that he is solely responsible for the nature of the use determined by him for the Smartmidia website, and the management of the Smartmidia website, to the fullest extent permitted by law, disclaims all responsibility for any losses, damages, expenses or expenses incurred by the user or suffered by him or any other party as a result of the use of the website Smartmidia, or the inability to use it.
Service interruptions, omissions and errors<br></br>

The site administration is doing its best to ensure and maintain the continuation of the work of the website without problems, although errors and omissions, service interruptions and delays may occur at any time, and in such cases we will expect users to be patient until the service returns to normal.<br></br>
Subscriber account, password and information security<br></br>

The subscriber chooses a password / password for his account, and an e-mail of his own to correspond with him, and the responsibility is to protect the mail and password and not to share or publish it on the subscriber, in the event of any transactions using this account, the subscriber will bear all the responsibilities resulting from that, without any responsibility on the Smartmidia website<br></br>
The subscriber bears full responsibility for all his content, which he uploads and publishes through the site.<br></br>
The user is obligated not to agree with a seller or buyer to deal with him outside the Smartmidia website. Failure to comply with this exposes the user to the suspension of his account
The subscriber is bound by the terms of use, and not to publish any content contrary to Islamic law or use the site for illegal purposes, including but not limited to, such as: piracy, publishing and distributing copied materials or programs, deception, forgery, fraud, threatening, annoying any person, company or group, publishing pornographic or sexual materials, spreading viruses or spyware, or placing links to sites containing such violations.<br></br>
It is forbidden to violate intellectual property rights, distort the reputation of a person, institution or company, or deliberately publish any information that causes harm to a company, person, country or group, and the subscriber is fully responsible for everything he provides through his account on the site<br></br>
It is strictly forbidden to use the site's services for political purposes, and it is forbidden to attack any Arab or Islamic country in any way.
</p>
<h3 className="font-bold">Sign up</h3>
<p className="text-gray-700 text-xs sm:text-sm mb-3">Some parts of the Site are only open to registered subscribed members after providing certain personal information about them. Upon registering on the site, the subscriber agrees that the information entered by him is complete and accurate, and he undertakes that he will not register on the site or attempt to enter it impersonating another subscriber name and will not use a name that the administration may deem inappropriate, such as phone numbers, impersonating names of famous personalities, site links, incomprehensible names, and the like. He is also obligated not to register more than one account on the Smartmidia website, and when the same person uses more than one account, he exposes all his accounts to final suspension without returning to him.<br></br>
Content censorship<br></br>

The administration of the Smartmidia website reserves the right to monitor any content entered by the subscriber without being obligated to do so, as it cannot monitor all the subscribers' entries, so it reserves the right (without obligation) to delete, remove or edit any entered materials that would violate the terms and conditions of the site without reference to the user. Local, international and foreign copyright laws and international treaties protect all content of this site, and by subscribing to it, the subscriber implicitly and expressly agrees to be bound by the copyright notices that appear on its pages.<br></br>

This policy is subject to constant change and development, please review it periodically and contact us through the Help Center to inquire about any of its terms.

</p>
      </div>
    </div>
      <ScrollToTop />
</>
  );
}

export default About;
