import React from 'react';

const Employee = () => {
  return (
    <>
      <section className="employees-section py-12 bg-gray-100">
        <h2 className="text-center text-3xl font-bold mb-8">Meet Our Team</h2>
        <div className="p-10 container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Employee 1 */}
          <div className="employee-item border border-gray-300 rounded-lg shadow-lg p-4 text-center w-full">
            <img 
              src="/assets/Untitled.jpeg"  // Use your actual image path
              alt="Employee 1" 
              className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
            />
            <h3 className="text-xl font-semibold">John Doe</h3>
            <p className="text-gray-600">Project Manager</p>
          </div>

          {/* Employee 2 */}
          <div className="employee-item border border-gray-300 rounded-lg shadow-lg p-4 text-center w-full">
            <img 
              src="/assets/05-27-20-2.webp"  // Use your actual image path
              alt="Employee 2" 
              className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
            />
            <h3 className="text-xl font-semibold">Jane Smith</h3>
            <p className="text-gray-600">Lead Designer</p>
          </div>

          {/* Employee 3 */}
          <div className="employee-item border border-gray-300 rounded-lg shadow-lg p-4 text-center w-full">
            <img 
              src="/assets/Untitlesd.jpeg"  // Use your actual image path
              alt="Employee 3" 
              className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
            />
            <h3 className="text-xl font-semibold">Tima Pitt</h3>
            <p className="text-gray-600">Team Leader</p>
          </div>

          {/* Employee 4 */}
          <div className="employee-item border border-gray-300 rounded-lg shadow-lg p-4 text-center w-full">
            <img 
              src="/assets/Untistled.jpeg"  // Use your actual image path
              alt="Employee 4" 
              className="w-32 h-32 object-cover rounded-full mx-auto mb-4" 
            />
            <h3 className="text-xl font-semibold">Ahmad Smith</h3>
            <p className="text-gray-600">Human Resources</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Employee;
