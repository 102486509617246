import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800 p-4 flex justify-between items-center relative">
      <div className="logo flex items-center justify-center">
        <img src="" alt="Logo" className="h-10 mr-4" />
        <span className="text-white text-xl">MyApp</span>
      </div>
    <div>
      <button className="text-white md:hidden" onClick={toggleMenu}>
        {/* Hamburger Icon */}
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
      <ul className={`md:flex md:space-x-4 absolute md:static top-full left-0 w-full bg-gray-800 ${isOpen ? 'block' : 'hidden'} md:block`}>
        <li><Link to="/" className="block py-2 px-4 text-white hover:text-gray-400">Home</Link></li>
        <li><Link to="/about" className="block py-2 px-4 text-white hover:text-gray-400">Privacy security</Link></li>
        <li><Link to="/contact" className="block py-2 px-4 text-white hover:text-gray-400">Contact us</Link></li>
      </ul>
    </div>
    </nav>
  );
};

export default Navbar;
