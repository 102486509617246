import React, { useState, useEffect } from 'react';
import '../style/ScrollToTop.css';
const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  // Handle scroll event to show/hide the button
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowScroll(true); // Show the button when scrolled down 300px
      } else {
        setShowScroll(false); // Hide the button otherwise
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll
    });
  };

  return (
    <div
      className={`scroll-to-top ${showScroll ? 'visible' : ''}`}
      onClick={scrollToTop}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="18 15 12 9 6 15"></polyline>
      </svg>
    </div>
  );
};

export default ScrollToTop;
